import request from "@/core/services/axios";

//教案详情
export function getTeachingPlanInfo(id) {
  return request({
    url: "/resource/teachassistrelation/getteachassistinfo/" + id,
    method: "get",
  });
}

export function upload(obj) {
  return request({
    url: "/resource/exampaperrelation/my/upload",
    method: "post",
    data: obj,
  });
}

//教材详情
export function getTeachBookInfo(id) {
  return request({
    url: "/resource/teachbookrelation/getteachbookinfo/" + id,
    method: "get",
  });
}
