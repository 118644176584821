<template>
  <div class="back" @click="backPage()">
    <img src="@/assets/imgs/icon-back.png" alt="返回上一页" />
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import router from "@/router";

export default {
  name: "WTGoBack",
  props: {
    defaultURL: String,
  },
  computed: {
    ...mapGetters(["layoutConfig"]),
  },
  methods: {
    // 返回
    backPage() {
      if (this.defaultURL) {
        router.push({ path: this.defaultURL });
      } else {
        router.go(-1);
      }
    },
  },
};
</script>

<style scoped lang="scss">
.back {
  width: 16px;
  height: 16px;
  margin-right: 8px;
  display: inline;
  vertical-align: middle;
  cursor: pointer;
  img {
    width: 16px;
    height: 16px;
    vertical-align: baseline;
  }
}
</style>
