<!-- 我的资源-微课 -->
<template>
  <div
    v-loading.fullscreen.lock="videoUploading"
    class="create-lesson"
    element-loading-text="视频文件上传中"
  >
    <div class="lesson-title">
      <div class="title">
        <WTGoBack />
        <span>创建微课</span>
      </div>
      <div class="btn">
        <!-- <div class="cancel" @click="backUrl()">上一步</div>
        <div class="save" @click="saveLessonData()">
          完成 <i class="el-icon-arrow-right"></i>
        </div> -->
      </div>
    </div>
    <div class="lesson-content">
      <div class="step">
        <div class="step-title">
          <div v-if="stepIndex < 1" class="step-icon num">1</div>
          <div v-else class="step-icon finish">
            <i class="el-icon-check"></i>
          </div>
          <div class="step-label">微课设置</div>
        </div>
        <div class="step-form">
          <el-form
            ref="microLessonForm"
            :model="microLessonForm"
            :rules="microLessonRules"
            label-width="100px"
          >
            <div class="form-item-box">
              <el-form-item label="微课名称" prop="name">
                <el-input v-model="microLessonForm.name"></el-input>
              </el-form-item>
            </div>
            <div class="form-item-box">
              <el-form-item label="学段：" prop="level">
                <el-radio-group
                  v-model="microLessonForm.level"
                  @change="refreahGradeOptions(microLessonForm.level)"
                >
                  <el-radio
                    v-for="(item, key) in levelOptions"
                    :key="key"
                    :label="item.value"
                  >
                    {{ item.label }}
                  </el-radio>
                </el-radio-group>
              </el-form-item>
            </div>
            <div class="form-item-box">
              <el-form-item label="年级：" prop="resGradeId">
                <el-radio-group
                  v-model="microLessonForm.resGradeId"
                  @change="refreshResGrade"
                >
                  <el-radio
                    v-for="(item, key) in showGradeOptions"
                    :key="key"
                    :label="item.value"
                  >
                    {{ item.label }}
                  </el-radio>
                </el-radio-group>
              </el-form-item>
            </div>
            <div class="form-item-box">
              <el-form-item label="科目：" prop="subjectId">
                <el-radio-group
                  v-model="microLessonForm.subjectId"
                  @change="refreshSubject()"
                >
                  <el-radio
                    v-for="(item, key) in subjectOptions"
                    :key="key"
                    :label="item.value"
                  >
                    {{ item.label }}
                  </el-radio>
                </el-radio-group>
              </el-form-item>
            </div>
            <div class="form-item-box">
              <el-form-item label="微课模板：" prop="templateType">
                <span class="tips">模板创建后不可修改</span>
                <div>
                  <el-radio v-model="microLessonForm.templateType" label="1"
                    >文档+视频(带白板)</el-radio
                  >
                  <el-radio v-model="microLessonForm.templateType" label="2"
                    >纯视频</el-radio
                  >
                </div>
              </el-form-item>
            </div>
            <div class="form-item-box">
              <el-form-item label="微课类型" prop="type">
                <el-radio-group v-model="microLessonForm.type">
                  <el-radio
                    v-for="(item, key) in microLessonsType"
                    :key="key"
                    :label="item.value"
                    >{{ item.label }}</el-radio
                  >
                </el-radio-group>
              </el-form-item>
            </div>
            <div v-if="microLessonForm.type == 1" class="form-item-box">
              <div class="self-form-item">
                <div class="point-list">
                  <div
                    v-for="(item, key) in loreTureArrName"
                    :key="key"
                    class="point-item"
                  >
                    <div class="name">{{ item }}</div>
                    <i class="el-icon-remove" @click="delPoint(item, key)"></i>
                  </div>
                </div>
                <div class="add-btn" @click="addPoint()">添加知识点</div>
              </div>
            </div>
            <div v-if="microLessonForm.type == 1" class="form-item-box">
              <div class="self-form-item">
                <div class="point-list">
                  <div
                    v-for="(item, key) in chaptersSelectName"
                    :key="key"
                    class="point-item"
                  >
                    <div class="name">{{ item }}</div>
                    <i class="el-icon-remove" @click="delChapter(key)"></i>
                  </div>
                </div>
                <div class="add-btn" @click="addChapter()">添加章节</div>
              </div>
            </div>
            <div class="form-item-box">
              <el-form-item label="课程封面图" prop="imgCover">
                <div v-if="imageCoverUrl.length < 1" class="upload-cover">
                  <el-upload
                    ref="coverUpload"
                    class="cover-uploader"
                    list-type="picture"
                    :action="uploadFileUrl"
                    :headers="uploadFileHeaders"
                    :accept="uploadImageAccept"
                    :multiple="false"
                    :limit="1"
                    :auto-upload="false"
                    :on-change="handleCoverOnChange"
                    :before-remove="handleCoverBeforeRemove"
                    :on-exceed="handleCoverOnExceed"
                    :http-request="handleCoverHttpRequest"
                  >
                    <el-button slot="trigger" size="small" type="primary">
                      选择文件...
                    </el-button>
                    <el-button
                      size="small"
                      type="success"
                      :loading="coverUploading"
                      @click="uploadCover"
                    >
                      上传到服务器
                    </el-button>
                  </el-upload>
                </div>
                <div v-else class="upload-image">
                  <img :src="imageCoverUrl" alt="" style="width: 160px" />
                  <i class="el-icon-delete" @click="delCover()"></i>
                </div>
              </el-form-item>
            </div>
            <div class="form-item-box">
              <el-form-item label="课程详情图" prop="imgDetails">
                <div class="upload-cover">
                  <el-upload
                    ref="detailUpload"
                    class="cover-uploader"
                    list-type="picture"
                    :action="uploadFileUrl"
                    :headers="uploadFileHeaders"
                    :accept="uploadImageAccept"
                    :auto-upload="false"
                    :on-change="handleDetailOnChange"
                    :before-remove="handleDetailBeforeRemove"
                    :http-request="handleDetailHttpRequest"
                  >
                    <el-button slot="trigger" size="small" type="primary">
                      选择文件...
                    </el-button>
                    <el-button
                      size="small"
                      type="success"
                      :loading="detailUploading"
                      @click="uploadDetail"
                    >
                      上传到服务器
                    </el-button>
                  </el-upload>
                </div>
                <div
                  v-if="uploadDetailImagesList.length > 0"
                  class="uploadedImage"
                >
                  <div>已上传图片</div>
                  <div class="image-list">
                    <div
                      v-for="(item, key) in uploadDetailImagesList"
                      :key="key"
                      class="upload-image"
                    >
                      <img :src="item" alt="" style="width: 160px" />
                      <i class="el-icon-delete" @click="delDetail(key)"></i>
                    </div>
                  </div>
                </div>
              </el-form-item>
            </div>
            <div class="form-item-box">
              <el-form-item label="分享至" prop="share">
                <el-radio-group v-model="microLessonForm.share">
                  <el-radio
                    v-for="(item, key) in shareOptions"
                    :key="key"
                    :label="item.value"
                    >{{ item.label }}</el-radio
                  >
                </el-radio-group>
              </el-form-item>
            </div>
          </el-form>
        </div>
      </div>
      <div class="step">
        <div class="step-title">
          <div v-if="stepIndex != 2" class="step-icon num">2</div>
          <div v-else class="step-icon finish">
            <i class="el-icon-check"></i>
          </div>
          <div class="step-label">课程设置</div>
        </div>
        <div class="step-form">
          <el-form ref="microCourseForm" label-width="100px">
            <div class="form-item-box">
              <div class="self-course-item">
                <div class="course-list">
                  <div
                    v-for="(item, key) in courseList"
                    :key="key"
                    class="course-item"
                  >
                    <div
                      class="name"
                      :class="{ on: courseIndex == key }"
                      @click="setCourse(item, key)"
                    >
                      {{ item.parentOrder }}
                    </div>
                  </div>
                </div>
                <div class="course-btn">
                  <div
                    v-if="courseList.length > 0"
                    class="course-del btn"
                    @click="delCourse()"
                  >
                    删除
                  </div>
                  <div class="course-add btn" @click="addCourse()">
                    <i class="el-icon-plus"></i>
                    添加课程
                  </div>
                </div>
              </div>
            </div>
            <div v-if="courseList.length > 0" class="form-item-box">
              <el-form-item label="课程名称">
                <el-input
                  v-model="courseName"
                  @change="setCourseName()"
                ></el-input>
              </el-form-item>
            </div>
            <div v-if="courseList.length > 0" class="form-item-box">
              <div class="self-course-item">
                <div class="course-list">
                  <div
                    v-for="(item, key) in courseList[courseIndex].lessons"
                    :key="key"
                    class="course-item"
                  >
                    <div
                      class="name"
                      :class="{ on: lessonIndex == key }"
                      @click="setLesson(item, key)"
                    >
                      {{ "第" + item.order + "节" }}
                    </div>
                  </div>
                </div>
                <div class="course-btn">
                  <div
                    v-if="courseList.length > 0"
                    class="course-del btn"
                    @click="delCourse()"
                  >
                    删除
                  </div>
                  <div class="course-add btn" @click="addLesson()">
                    <i class="el-icon-plus"></i>
                    添加课堂
                  </div>
                </div>
              </div>
            </div>
            <div
              v-if="courseList[courseIndex].lessons.length > 0"
              :key="refreshKey"
              class="form-item-box"
            >
              <el-form-item label="课堂名称">
                <el-input
                  v-model="lessonName"
                  @change="setLessonName()"
                ></el-input>
              </el-form-item>
            </div>
            <div
              v-if="courseList[courseIndex].lessons.length > 0"
              class="form-item-box"
            >
              <el-form-item label="老师">
                <el-select
                  v-model="teacherId"
                  placeholder="请选择老师"
                  @change="setTeacher()"
                >
                  <el-option
                    v-for="item in teacherOption"
                    :key="item.id"
                    :label="item.name"
                    :value="item.id"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
            </div>
            <div
              v-if="courseList[courseIndex].lessons.length > 0"
              class="form-item-box"
            >
              <el-form-item label="课程URL">
                <div class="course-url">
                  <el-input
                    v-model="courseUrl"
                    @change="setCourseUrl()"
                    @input="setCourseUrl()"
                  ></el-input>
                  <el-upload
                    ref="videoUpload"
                    class="upload-video"
                    :limit="1"
                    :action="uploadFileUrl"
                    :headers="uploadFileHeaders"
                    :accept="uploadVideoAccept"
                    :auto-upload="false"
                    :on-change="handleVideoOnChange"
                    :before-remove="handlevideoBeforeRemove"
                    :on-exceed="handleVideoOnExceed"
                    :http-request="handleVideoHttpRequest"
                  >
                    <el-button slot="trigger" size="small" type="primary">
                      选取视频文件...
                    </el-button>
                    <el-button
                      size="small"
                      type="success"
                      :loading="videoUploading"
                      @click="uploadVideo"
                    >
                      上传视频文件
                    </el-button>
                  </el-upload>
                </div>
              </el-form-item>
            </div>
          </el-form>
        </div>
      </div>
    </div>
    <div class="lesson-footer">
      <div class="footer-btn" @click="createLesson">保 存</div>
      <!-- <div class="footer-btn" @click="recordLesson">录制微课</div> -->
    </div>
    <!-- 选择知识点弹窗 -->
    <el-dialog
      class="point-dialog"
      title="选择知识点"
      :visible.sync="knowledgePointDialogVisible"
      width="856px"
      :close-on-click-modal="false"
      :lock-scroll="true"
      @close="closePointsDialog"
    >
      <div class="by-points">
        <div>
          <el-tree
            ref="tree"
            :key="newKeys"
            :data="knowledgeData"
            show-checkbox
            node-key="id"
            :default-checked-keys="loreTureArrid"
            :default-expanded-keys="defaultPointsExpendedKeys"
            :props="defaultProps"
            @check="elTreeChange"
          >
          </el-tree>
        </div>
        <div style="width: 300px">
          <div class="search-item">
            <el-input
              v-model="pointInput"
              class="input-with-select"
              @keyup.enter.native="searchLore"
            ></el-input>
            <div class="btn" @click="searchLore">搜索</div>
          </div>
          <el-checkbox-group
            v-model="loreTureArrid"
            style="width: 200px; margin-top: 20px"
          >
            <template v-for="(v, i) in TreeArray">
              <el-checkbox :key="i" :label="v.id">{{ v.name }}</el-checkbox>
            </template>
          </el-checkbox-group>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <div class="btn" @click="closePointsDialog">确定</div>
      </span>
    </el-dialog>
    <!-- 选择章节 -->
    <el-dialog
      class="point-dialog"
      title="选择章节"
      :visible.sync="chaptersDialogVisible"
      width="856px"
      :close-on-click-modal="false"
      :lock-scroll="true"
      @close="closeChapteesDialog"
    >
      <div class="select">
        <span style="font-weight: 700">教材</span>
        <el-select
          v-model="seletedBook"
          placeholder="请选择"
          clearable=""
          @change="refreshSeletedBook()"
        >
          <el-option
            v-for="item in textbooksArr"
            :key="item.id"
            :label="item.name"
            :value="item.id"
          >
            <span
              >{{ item.gradeName
              }}{{ getLabelByValue(item.subjectId, subjectOptions)
              }}{{ item.editionName }}{{ item.name }}</span
            >
          </el-option>
        </el-select>
      </div>
      <div class="by-chapters">
        <div style="width: 100%">
          <el-tree
            ref="chaptersTree"
            :key="newKeys"
            :data="chaptersData"
            show-checkbox
            node-key="id"
            :default-checked-keys="chaptersSelectIds"
            :default-expanded-keys="defaultChaptersExpendedKeys"
            :props="defaultProps"
            @check="chaptersChange"
          >
          </el-tree>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <div class="btn" @click="closeChapteesDialog()">确定</div>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import {
  levelOptions,
  gradeHighOptions,
  gradeMiddleOptions,
  subjectOptions,
} from "@/core/util/constdata";
import { getStore } from "@/core/util/store";
import {
  getTeachingBooksList,
  createMicroLesson,
  uploadLesson,
  teacherSearch,
} from "@/core/api/resource/myResource";
import { getTeachBookInfo } from "@/core/api/resource/resource";
import { getQuestionPoint } from "@/core/api/exam/examLore";
import WTGoBack from "@/components/GoBack.vue";
import router from "@/router";
const microLessonsType = [
  {
    label: "知识点/章节微课",
    value: "1",
  },
  {
    label: "专题微课",
    value: "2",
  },
];
export default {
  name: "CreateMicroLesson",
  components: {
    WTGoBack,
  },
  props: {
    resourseTypeData: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      //章节
      chaptersDialogVisible: false,
      textbooksArr: [], //所有教材
      seletedBook: "", //选择教材,页面展示用
      seletedBookId: "", //所选教材id
      chaptersArr: [], //所选教材所有章节
      chaptersData: [], //页面展示用
      chaptersSelectIds: [], //所选章节id
      chaptersSelectName: [],
      defaultChaptersExpendedKeys: [],
      //步骤条
      stepIndex: 1,
      microLessonForm: {
        name: "",
        level: "3", //3-高中 2-初中
        resGradeId: "",
        subjectId: "",
        templateType: "1", //微课模板 1-文档+视频(带白板) 2-纯视频
        type: "1", //微课类型 1-知识点/章节微课 2-专题微课 3-试题微课
        knowledgeIds: "", //知识点ID 多个以#隔开
        knowledgeNames: "", //知识点名称 多个以#隔开
        chapterIds: "", //章节ID 多个以#隔开
        chapterNames: "", //章节名称 多个以#隔开
        imgCover: "", //课程封面图
        imgDetails: "", //课程详情图
        questionId: 0, //试题ID 如果该微课不是试题微课类型，该值传0
        share: 0, //0-精品微课 1-校本微课 2-不分享
        lessons: [], //课程列表
      },
      microLessonRules: {
        name: [{ required: true, message: "请输入名称", trigger: "blur" }],
        level: [{ required: true, message: "请选择学段", trigger: "change" }],
        resGradeId: [
          { required: true, message: "请选择年级", trigger: "change" },
        ],
        subjectId: [
          { required: true, message: "请选择科目", trigger: "change" },
        ],
        templateType: [
          { required: true, message: "请选择微课模板", trigger: "change" },
        ],
        type: [
          { required: true, message: "请选择微课类型", trigger: "change" },
        ],
        share: [{ required: true, message: "请选择", trigger: "change" }],
        knowledgeIds: [
          {
            type: "array",
            required: true,
            message: "请至少选择一个知识点",
            trigger: "change",
          },
        ],
        chapterIds: [
          {
            type: "array",
            required: true,
            message: "请至少选择一个章节",
            trigger: "change",
          },
        ],
      },
      imageCoverUrl: "",
      // option数据
      levelOptions: levelOptions(),
      gradeHighOptions: gradeHighOptions,
      gradeMiddleOptions: gradeMiddleOptions,
      showGradeOptions: gradeHighOptions, //年级
      subjectOptions: subjectOptions,
      microLessonsType: microLessonsType,
      shareOptions: [
        { value: 0, label: "精品微课" },
        { value: 1, label: "校本微课" },
        { value: 2, label: "不分享" },
      ],
      //图片上传
      uploadFileUrl: "/resource/file/upload",
      uploadFileHeaders: {
        Authorization: "Bearer " + getStore({ name: "access_token" }),
      },
      uploadImageAccept: ".jpg,.jpeg,.png,.bmp,.gif",
      coverFileList: [],
      coverFileData: new FormData(),
      coverUploading: false,
      detailFileList: [],
      detailFileData: new FormData(),
      detailUploading: false,
      uploadDetailImagesList: [],
      //课程
      courseList: [
        {
          parentOrder: 1,
          parentName: "",
          lessons: [],
          length: "", //时长
          url: "",
        },
      ],
      courseName: "",
      courseUrl: "",
      courseIndex: 0,
      //课堂
      lessonList: [],
      lessonName: "",
      lessonIndex: 0,
      teacherId: "",
      teacherOption: [],
      //视频上传
      uploadVideoAccept: ".mp4,.ts,.wmv,.avi,.mov",
      videoFileList: [],
      videoFileData: new FormData(),
      videoUploading: false,
      // 选择知识点弹窗
      knowledgePointDialogVisible: false,
      defaultProps: {
        children: "child",
        label: "name",
      },
      newKeys: new Date().getTime(),
      knowledgeData: [], //页面展示用所有知识点(树形结构)
      loreArr: [], //所有知识点的数组
      loreTureArrid: [], //默认勾选的节点的 key 的数组
      loreTureArrName: [],
      defaultPointsExpendedKeys: [], //默认展开的节点的 key 的数组
      pointInput: "", //知识点搜索
      TreeArray: [], //搜索结果
      displayPointIds: [],
      displayPointName: [],
      ajaxPointIds: [],
      ajaxChapterIds: [],
      refreshKey: 0,
    };
  },
  computed: {
    ...mapGetters({ getLevelId: "getLevelId", getSubjectId: "getSubjectId" }),
  },
  watch: {
    loreTureArrid() {
      this.newKeys = new Date().getTime();
      this.defaultPointsExpendedKeys = this.loreTureArrid;
      this.loreTureArrName = [];
      this.ajaxPointIds = [];
      this.loreArr.forEach((i) => {
        if (this.loreTureArrid.indexOf(i.id) != -1 && i.child.length == 0) {
          this.loreTureArrName.push(i.name);
          this.ajaxPointIds.push(i.number);
        }
        if (this.loreTureArrid.indexOf(i.id) != -1 && i.child.length > 0) {
          this.loreTureArrid.splice(this.loreTureArrid.indexOf(i.id), 1);
        }
      });
    },
    chaptersSelectIds() {
      this.newKeys = new Date().getTime();
      this.defaultChaptersExpendedKeys = this.chaptersSelectIds;
      this.chaptersSelectName = [];
      this.ajaxChapterIds = [];
      this.chaptersArr.forEach((i) => {
        if (this.chaptersSelectIds.indexOf(i.id) != -1 && i.child.length == 0) {
          this.chaptersSelectName.push(i.name);
          this.ajaxChapterIds.push(i.number);
        }
        if (this.chaptersSelectIds.indexOf(i.id) != -1 && i.child.length > 0) {
          this.chaptersSelectIds.splice(
            this.chaptersSelectIds.indexOf(i.id),
            1
          );
        }
      });
    },
    courseUrl() {
      let obj = this.courseList[this.courseIndex].lessons[this.lessonIndex];
      obj.url = this.courseUrl;
      this.courseList = JSON.parse(JSON.stringify(this.courseList));
    },
  },
  created() {
    this.resetBasePageData();
  },
  methods: {
    getLabelByValue(value, data) {
      let label = "";
      data.forEach((item) => {
        if (item.value === value) {
          label = item.label;
        }
      });
      return label;
    },
    backUrl() {
      router.go(-1);
    },
    resetBasePageData() {
      this.microLessonForm.level = this.$route.query.level;
      this.microLessonForm.subjectId = Number(this.$route.query.subject);
      //年级(初中默认初一，高中默认高一)
      if (this.$route.query.grade == 0) {
        this.microLessonForm.resGradeId = this.showGradeOptions[0].value;
      } else {
        this.microLessonForm.resGradeId = this.$route.query.grade;
      }
      //
      teacherSearch({
        current: 1,
        size: 1000,
      }).then((res) => {
        this.teacherOption = res.data.data.records;
      });
    },
    saveLessonData() {
      // console.log("saveLessonData");
    },
    refreahGradeOptions(level) {
      if (level == 2) {
        this.showGradeOptions = gradeMiddleOptions;
      } else {
        this.showGradeOptions = gradeHighOptions;
      }
      this.clearKnowledgePoint();
      this.clearChapters();
    },
    refreshResGrade() {
      this.clearKnowledgePoint();
      this.clearChapters();
    },
    refreshSubject() {
      this.clearKnowledgePoint();
      this.clearChapters();
    },
    //添加知识点
    addPoint() {
      this.knowledgePointDialogVisible = true;
      this.getKnowledgeData();
    },
    delPoint(key) {
      this.loreTureArrid.splice(key, 1);
    },
    closePointsDialog() {
      this.knowledgePointDialogVisible = false;
      this.knowledgeData = [];
    },
    clearKnowledgePoint() {
      this.loreTureArrid = [];
      this.TreeArray = [];
      this.pointInput = "";
    },
    //添加章节
    addChapter() {
      this.chaptersDialogVisible = true;
      getTeachingBooksList({
        levelId: this.microLessonForm.level,
        subjectId: this.microLessonForm.subjectId,
      }).then((res) => {
        this.textbooksArr = res.data.data;
      });
    },
    refreshSeletedBook() {
      if (this.seletedBookId != this.seletedBook) {
        this.chaptersSelectIds = [];
      }
      this.seletedBookId = this.seletedBook;
      this.textbooksArr.forEach((item) => {
        if (item.id == this.seletedBookId) {
          this.seletedBook =
            item.gradeName +
            this.getLabelByValue(item.subjectId, subjectOptions) +
            item.editionName +
            item.name;
        }
      });
      getTeachBookInfo(this.seletedBookId).then((res) => {
        this.chaptersArr = res.data.data.resChapters;
        let arrList = this.chaptersArr;
        this.chaptersData = [];
        arrList
          .filter((i) => i.rank == 1)
          .forEach((i) => {
            let obj = i;
            obj.child = this.convertToTreeData(arrList, i.number);
            this.chaptersData.push(obj);
          });
      });
    },
    closeChapteesDialog() {
      //init
      this.chaptersDialogVisible = false;
      this.textbooksArr = [];
      this.chaptersData = [];
      this.seletedBook = "";
    },
    chaptersChange() {
      this.chaptersSelectIds = this.$refs.chaptersTree.getCheckedKeys();
    },
    clearChapters() {
      this.chaptersSelectIds = [];
      this.seletedBook = "";
    },
    delChapter(index) {
      this.chaptersSelectIds.splice(index, 1);
    },

    //上传封面
    handleCoverOnExceed() {
      this.$message.error("最多只能上传一张封面图");
    },
    handleCoverBeforeRemove(file, fileList) {
      this.coverFileList = fileList;
    },
    handleCoverHttpRequest(file) {
      this.coverFileData.append("file", file.file);
    },
    handleCoverOnChange(file, fileList) {
      let existFile = fileList
        .slice(0, fileList.length - 1)
        .find((f) => f.name === file.name);
      if (existFile) {
        this.$message.error("当前文件已经存在！");
        fileList.pop();
      }
      let checkRes = this.checkImageFile(file);
      if (!checkRes) {
        fileList.pop();
      }
      this.coverFileList = fileList;
    },
    checkImageFile(file) {
      if (
        !file.name.endsWith(".jpg") &&
        !file.name.endsWith(".jpeg") &&
        !file.name.endsWith(".png") &&
        !file.name.endsWith(".bmp") &&
        !file.name.endsWith(".gif")
      ) {
        this.$message.error("只能上传图片文件！");
        return false;
      }
      return true;
    },
    uploadCover() {
      if (this.coverFileList.length === 0) {
        this.$message.error("请先选取封面图片");
        return;
      }
      this.coverUploading = true;
      this.$refs.coverUpload.submit();
      uploadLesson(this.coverFileData).then((res) => {
        this.coverUploading = false;
        this.$refs.coverUpload.clearFiles();
        this.$message.success("上传封面成功！");
        this.coverFileList = [];
        this.imageCoverUrl = res.data.data.url;
      });
    },
    delCover() {
      this.imageCoverUrl = "";
    },
    //上传详情
    handleDetailBeforeRemove(file, fileList) {
      this.detailFileList = fileList;
    },
    handleDetailHttpRequest(file) {
      this.detailFileData.append("file", file.file);
    },
    handleDetailOnChange(file, fileList) {
      let existFile = fileList
        .slice(0, fileList.length - 1)
        .find((f) => f.name === file.name);
      if (existFile) {
        this.$message.error("当前文件已经存在！");
        fileList.pop();
      }
      let checkRes = this.checkImageFile(file);
      if (!checkRes) {
        fileList.pop();
      }
      this.detailFileList = fileList;
    },
    uploadDetail() {
      if (this.detailFileList.length === 0) {
        this.$message.error("请先选取详情图片");
        return;
      }
      this.detailUploading = true;
      this.$refs.detailUpload.submit();
      uploadLesson(this.detailFileData)
        .then((res) => {
          this.detailUploading = false;
          this.$refs.detailUpload.clearFiles();
          this.detailFileList = [];
          this.detailFileData = new FormData();
          this.$message.success("上传详情图片成功！");
          this.uploadDetailImagesList.push(res.data.data.url);
        })
        .catch(() => {
          this.detailUploading = false;
        });
    },
    delDetail(index) {
      this.uploadDetailImagesList.splice(index, 1);
    },
    //课程
    setCourse(item, key) {
      this.courseIndex = key;
      this.lessonIndex = 0;
      this.courseName = this.courseList[this.courseIndex].parentName;
      this.refreshKey = new Date().getTime();
    },
    setCourseName() {
      this.courseList[this.courseIndex].parentName = this.courseName;
    },

    addCourse() {
      this.stepIndex = 2;
      let obj = {
        parentOrder: this.courseList.length + 1,
        parentName: "",
        length: "", //时长
        url: "",
        lessons: [],
      };
      this.courseList.push(obj);
      this.courseList = JSON.parse(JSON.stringify(this.courseList));
      // console.log("courseList==", this.courseList);
    },
    delCourse() {
      this.courseList.splice(this.courseIndex, 1);
      if (this.courseList.length > 0) {
        this.courseName = this.courseList[this.courseIndex].name;
      } else {
        this.courseName = "";
        this.stepIndex = 0;
      }
    },
    //课堂
    setLesson(item, key) {
      this.lessonIndex = key;
      this.lessonName =
        this.courseList[this.courseIndex].lessons[this.lessonIndex].name;
      this.teacherId =
        this.courseList[this.courseIndex].lessons[this.lessonIndex].teacherId;
      this.courseUrl =
        this.courseList[this.courseIndex].lessons[this.lessonIndex].url;
      this.courseList = JSON.parse(JSON.stringify(this.courseList));
    },
    setLessonName() {
      let obj = this.courseList[this.courseIndex].lessons[this.lessonIndex];
      obj.name = this.lessonName;
      obj.url = this.courseUrl;
      this.courseList = JSON.parse(JSON.stringify(this.courseList));
    },
    setCourseUrl() {
      let obj = this.courseList[this.courseIndex].lessons[this.lessonIndex];
      obj.url = this.courseUrl;
      this.courseList = JSON.parse(JSON.stringify(this.courseList));
    },
    addLesson() {
      let obj = {
        order: this.courseList[this.courseIndex].lessons.length + 1,
        name: "",
        teacherId: "",
        url: "",
        length: "",
      };
      // this.lessonName = "";
      // this.teacherId = "";
      // this.courseUrl = "";
      this.courseList[this.courseIndex].lessons.push(obj);
      this.courseList = JSON.parse(JSON.stringify(this.courseList));
    },
    delLesson() {
      let lessonObj = this.courseList[this.courseIndex].lessons;
      lessonObj.splice(this.lessonIndex, 1);
      if (lessonObj.length > 0) {
        this.lessonName = lessonObj[this.lessonIndex].name;
      } else {
        this.lessonName = "";
      }
      this.courseList = JSON.parse(JSON.stringify(this.courseList));
    },
    setTeacher() {
      let obj = this.courseList[this.courseIndex].lessons[this.lessonIndex];
      obj.teacherId = this.teacherId;
      this.courseList = JSON.parse(JSON.stringify(this.courseList));
    },
    //上传视频
    handleVideoOnExceed() {
      this.$message.error("每个只能上传一个视频文件");
    },
    handleVideoOnChange(file, fileList) {
      let existFile = fileList
        .slice(0, fileList.length - 1)
        .find((f) => f.name === file.name);
      if (existFile) {
        this.$message.error("当前文件已经存在！");
        fileList.pop();
      }
      let checkRes = this.checkVideoFile(file);
      if (!checkRes) {
        fileList.pop();
      }
      this.videoFileList = fileList;
    },
    checkVideoFile(file) {
      if (
        !file.name.endsWith(".mp4") &&
        !file.name.endsWith(".wmv") &&
        !file.name.endsWith(".ts") &&
        !file.name.endsWith(".avi") &&
        !file.name.endsWith(".mov")
      ) {
        this.$message.error("只能上传视频文件！");
        return false;
      }
      return true;
    },
    handlevideoBeforeRemove(file, fileList) {
      this.videoFileList = fileList;
    },
    handleVideoHttpRequest(file) {
      this.videoFileData.append("file", file.file);
    },
    uploadVideo() {
      if (this.videoFileList.length === 0) {
        return;
      }
      this.$refs.videoUpload.submit();
      this.videoUploading = true;
      uploadLesson(this.videoFileData)
        .then((res) => {
          this.$message.success("视频上传成功！");
          this.videoUploading = false;
          this.$refs.videoUpload.clearFiles();
          this.courseUrl = res.data.data.url;
          this.courseList[this.courseIndex].url = res.data.data.url;
        })
        .catch((res) => {
          this.videoUploading = false;
          this.$message.success(res.data.error);
        });
    },
    dataIsEmpty(field) {
      if (typeof field === "undefined" || field === null || field === "") {
        return true;
      } else {
        return false;
      }
    },
    //footer
    createLesson() {
      let isValid = true;
      this.$refs.microLessonForm.validate((valid) => {
        if (!valid) {
          this.$message.error("请检查必填字段是否填写");
          isValid = false;
        } else {
          if (this.ajaxPointIds.length < 1) {
            this.$message.error("请至少添加一个知识点");
            isValid = false;
            return;
          }
          if (this.ajaxChapterIds.length < 1) {
            this.$message.error("请至少添加一个章节");
            isValid = false;
            return;
          }
          if (this.imageCoverUrl.length < 1) {
            this.$message.error("请上传封面图片");
            isValid = false;
            return;
          }
          if (this.uploadDetailImagesList.length < 1) {
            this.$message.error("请上传课程详情图片");
            isValid = false;
            return;
          }
          if (this.courseList.length < 1) {
            this.$message.error("请设置相关课程");
            isValid = false;
            return;
          } else {
            this.courseList.forEach((item) => {
              if (item.parentName.length < 1) {
                this.$message.error("所有课程的课程名称都不能为空");
                isValid = false;
                return;
              }
              if (item.lessons.length < 1) {
                this.$message.error("请确认所有课程的相关课堂信息已设置");
                isValid = false;
                return;
              } else {
                item.lessons.forEach((item) => {
                  if (this.dataIsEmpty(item.name)) {
                    this.$message.error("请确认所有课程的相关课堂名称已设置");
                    isValid = false;
                    return;
                  }
                  if (this.dataIsEmpty(item.teacherId)) {
                    this.$message.error("请确认所有课程的相关课堂教师已设置");
                    isValid = false;
                    return;
                  }
                  if (this.dataIsEmpty(item.url)) {
                    this.$message.error("请确认所有课程的相关课堂视频已设置");
                    isValid = false;
                    return;
                  }
                });
              }
            });
          }
        }
      });
      if (!isValid) {
        return;
      }
      let _lessons = [];
      this.courseList.forEach((item) => {
        if (item.lessons.length > 0) {
          item.lessons.forEach((val) => {
            let obj = {
              parentOrder: item.parentOrder,
              parentName: item.parentName,
              order: val.order,
              name: val.name,
              length: val.length,
              url: val.url,
              teacherId: val.teacherId,
            };
            _lessons.push(obj);
          });
        }
      });
      this.microLessonForm.lessons = _lessons;
      this.microLessonForm.imgCover = this.imageCoverUrl;
      this.microLessonForm.imgDetails = this.uploadDetailImagesList.join(",");
      this.microLessonForm.knowledgeIds = this.ajaxPointIds.join("#");
      this.microLessonForm.knowledgeNames = this.loreTureArrName.join("#");
      this.microLessonForm.chapterIds = this.ajaxChapterIds.join("#");
      this.microLessonForm.chapterNames = this.chaptersSelectName.join("#");
      createMicroLesson(this.microLessonForm).then(() => {
        this.uploadDetailImagesList = [];
        this.backUrl();
      });
    },
    recordLesson() {
      // console.log("录制微课");
    },

    //知识点
    getKnowledgeData() {
      getQuestionPoint({
        level: this.microLessonForm.level,
        subjectId: this.microLessonForm.subjectId,
      }).then((res) => {
        this.loreArr = res.data.data.resQuestionPoints;
        let arrList = this.loreArr;
        arrList
          .filter((i) => i.rank == 1)
          .forEach((i) => {
            let obj = i;
            obj.child = this.convertToTreeData(arrList, i.number);
            this.knowledgeData.push(obj);
          });
      });
    },
    //递归形成树形结构知识点
    convertToTreeData(data, pid) {
      const result = [];
      let temp = [];
      for (let i = 0; i < data.length; i++) {
        if (data[i].number.slice(0, -1) == pid) {
          const obj = data[i];
          if (!obj.child) {
            obj.child = new Array();
          }
          temp = this.convertToTreeData(data, data[i].number);
          if (temp.length > 0) {
            obj.child = temp;
          }
          result.push(obj);
        }
      }
      return result;
    },
    elTreeChange() {
      this.loreTureArrid = this.$refs.tree.getCheckedKeys();
    },
    searchLore() {
      let arr = [];
      this.TreeArray = [];
      this.defaultPointsExpendedKeys = [];
      this.loreArr.forEach((i) => {
        if (i.name.indexOf(this.pointInput) >= 0) {
          arr.push(i.id);
          if (i.child.length == 0) {
            this.TreeArray.push(i);
          }
        }
      });
      if (arr.length == 0) {
        this.$message({
          message: "没有找到相似知识点",
          type: "warning",
        });
        return;
      }
      this.defaultPointsExpendedKeys = arr;
    },
  },
};
</script>
<style lang="scss">
.create-lesson {
  .lesson-content {
    .step {
      .step-form {
        .el-form {
          border-bottom: 1px solid #e0e0e0;
        }
        .el-form-item {
          margin-bottom: unset;
          padding: 4px 0;
          .el-form-item__label {
            margin-bottom: unset;
            text-align: left;
            color: rgba(0, 0, 0, 0.85);
          }
          .el-form-item__content {
            .el-input__inner {
              border: unset;
              background-color: #f5f5f5;
            }
            .el-radio-group {
              label {
                margin-bottom: 0;
              }
            }
          }
        }
        .cover-uploader {
          .el-upload-list {
            width: 672px;
            flex-wrap: wrap;
            display: flex;
            .el-upload-list__item {
              width: 214px;
              margin: 10px 10px 0 0;
              &:last-child {
                margin-bottom: 8px;
              }
            }
          }
        }
        .course-url,
        .cover-uploader {
          .el-button--primary {
            background-color: #ffffff;
            border-color: #2474ed;
            color: #2474ed;
          }
          .el-button--success {
            background-color: #2474ed;
            border-color: #2474ed;
            color: #ffffff;
            margin-left: 10px;
          }
        }
      }
    }
  }
  .point-dialog {
    .by-chapters,
    .by-points {
      .el-input {
        width: 180px;
      }
      .el-input__inner {
        border: dashed 2px #f5f5f5 !important;
        background-color: #ffffff !important;
        border-radius: 4px;
        height: 30px;
        width: 180px;
      }
    }
    .select {
      .el-select {
        margin-left: 40px;
        width: 90%;
      }
    }
  }
}
</style>

<style scoped lang="scss">
.create-lesson {
  width: 1220px;
  margin: 0px auto 50px;
  .lesson-title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    box-shadow: inset 0px -1px 0px #dddddd;
    padding: 0 64px;
    .title {
      padding: 16px 0;
      font-size: 24px;
      text-align: left;
      color: rgba(0, 0, 0, 0.85);
    }
    .btn {
      display: flex;
      cursor: pointer;
      .cancel {
        color: #2474ed;
        margin-right: 20px;
        padding: 6px;
      }
      .save {
        background-color: #2474ed;
        padding: 6px 15px;
        color: #ffffff;
        border-radius: 4px;
      }
    }
  }
  .lesson-content {
    margin-top: 50px;
    padding: 0 162px;
    .step {
      &:not(:last-child) {
        margin-bottom: 32px;
      }
      .step-title {
        display: flex;
        align-items: center;
        .step-icon {
          width: 32px;
          height: 32px;
          border-radius: 32px;
          text-align: center;
          line-height: 32px;
          margin-right: 18px;
        }
        .num {
          background-color: rgba(0, 0, 0, 0.05);
          color: rgba(0, 0, 0, 0.5);
        }
        .finish {
          background-color: #2474ed;
          color: #ffffff;
        }
        .step-label {
          font-weight: 500;
          font-size: 24px;
          color: rgba(0, 0, 0, 0.85);
        }
      }
      .step-form {
        padding: 0 62px;
        margin-top: 28px;
        .form-item-box {
          border-top: 1px solid #e0e0e0;
          display: flex;
          justify-content: space-between;
          align-items: center;
          flex-wrap: wrap;
          .el-form-item {
            width: 100%;
            .tips {
              margin-left: -20px;
              color: rgba(0, 0, 0, 0.5);
            }
            .teacher {
              display: flex;
              justify-content: flex-end;
              color: #2474ed;
              cursor: pointer;
            }
          }
          .upload-cover {
            display: flex;
            .cover-uploader {
              margin-bottom: 8px;
              .cover {
                width: 160px;
                height: 213px;
                display: block;
              }
            }
            .upload-btn {
              margin-left: 20px;
              .upload {
                color: #ffffff;
                cursor: pointer;

                height: 32px;
                line-height: 32px;
                background-color: #2474ed;
                text-align: center;
                border-radius: 4px;
                margin-top: 6px;
                width: 62px;
              }
              .tip {
                line-height: 16px;
                color: rgba(255, 0, 0, 0.5);
                margin-top: 10px;
              }
            }
          }
          .image-list {
            display: flex;
            width: 672px;
            flex-wrap: wrap;
          }
          .upload-image {
            position: relative;
            width: 160px;
            margin: 0 4px 8px;
            overflow: hidden;
            &:hover {
              .el-icon-delete {
                visibility: visible;
              }
            }
            img {
              width: 160px;
              height: 213px;
              // border: 1px dashed #d9d9d9;
              display: inline-block;
              border-radius: 4px;
            }
            .el-icon-delete {
              font-size: 20px;
              position: absolute;
              left: 0;
              top: 0;
              background-color: rgba(0, 0, 0, 0.5);
              color: #ffffff;
              width: 160px;
              height: 213px;
              line-height: 213px;
              text-align: center;
              border-radius: 4px;
              visibility: hidden;
            }
          }
          .course-url {
            // display: flex;
            justify-content: space-between;
            align-items: center;
            .upload-video {
            }
          }
        }
        .self-form-item {
          display: flex;
          padding: 8px 14px 8px 100px;
          justify-content: space-between;
          width: 100%;
          align-items: center;
          .point-list {
            display: flex;
            flex-wrap: wrap;
            width: 617px;
            .point-item {
              color: rgba(0, 0, 0, 0.5);
              display: flex;
              &:hover {
                color: #000000;
                .el-icon-remove {
                  visibility: visible;
                }
              }
              &:not(:last-child) {
                margin-right: 22px;
              }
              .el-icon-remove {
                color: #d81e06;
                font-size: 8px;
                margin-top: -4px;
                margin-left: -2px;
                visibility: hidden;
                cursor: pointer;
              }
            }
          }
          .add-btn {
            color: #2474ed;
            cursor: pointer;
            width: 80px;
            text-align: right;
          }
        }
        .self-course-item {
          display: flex;
          padding: 8px 0px;
          justify-content: space-between;
          width: 100%;
          .course-list {
            display: flex;
            width: 618px;
            flex-wrap: wrap;
            .course-item {
              margin-bottom: 4px;
              &:not(:last-child) {
                margin-right: 32px;
              }
              .name {
                padding: 6px;
                background-color: rgba(0, 0, 0, 0.04);
                border-radius: 4px;
                color: rgba(0, 0, 0, 0.85);
                cursor: pointer;
              }
              .on {
                color: rgba(255, 255, 255, 1);
                background-color: #2474ed;
              }
            }
          }
          .course-btn {
            display: flex;
            .btn {
              padding: 6px;
              cursor: pointer;
              &:first-child {
                color: rgba(252, 87, 67, 0.85);
                margin-right: 6px;
              }
              &:last-child {
                color: #2474ed;
              }
            }
            .course-add {
            }
            .course-del {
            }
          }
        }
      }
    }
  }
  .lesson-footer {
    margin: 80px auto 100px;
    display: flex;
    justify-content: center;
    .footer-btn {
      padding: 14px 82px;
      color: #ffffff;
      border-radius: 4px;
      cursor: pointer;
      &:first-child {
        background-color: rgba(0, 0, 0, 0.85);
        margin-right: 224px;
      }
      &:last-child {
        background-color: rgba(16, 198, 148, 1);
      }
    }
  }
  .point-dialog {
    .el-dialog__header {
      .el-dialog__title {
        color: rgba(0, 0, 0, 0.85);
      }
    }
    .el-dialog__body {
      padding: 30px 30px 30px 20px;
      .search-item {
        display: flex;
        align-items: center;
        justify-content: space-between;
        .btn {
          background-color: #2474ed;
          color: #ffffff;
          border-radius: 4px;
          margin-left: 12px;
          font-size: 14px;
          align-items: center;
          display: flex;
          height: 25px;
          width: 76px;
          justify-content: center;
          cursor: pointer;
        }
      }
      .by-points,
      .by-chapters {
        display: flex;
        justify-content: space-between;
      }
      .select {
        border-top: 1px solid #e0e0e0;
        border-bottom: 1px solid #e0e0e0;
        padding: 8px 0;
        margin-bottom: 20px;
      }
    }
    .el-dialog__footer {
      padding: 10px 30px 20px 20px;
      .dialog-footer {
        display: flex;
        justify-content: flex-end;
        .btn {
          padding: 10px 24px;
          background-color: #2474ed;
          color: #ffffff;
          border-radius: 4px;
          cursor: pointer;
        }
      }
    }
  }
}
</style>
